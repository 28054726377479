import React, { useEffect, useState } from "react";
import MenuIcon from "./../../../assets/images/Menu.svg";
import priceIcon from "./../../../assets/images/priceIcon.svg";
// import "./style.css";
import "./style.scss";
import { Button, Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggle, makeCustom, makeTrue } from "../../../reducers/rerenderSlice";

function Sidebar() {
  const dispatch = useDispatch();
  const rerender = useSelector((state) => state.rerender);

  const defaultLocation = useLocation();
  const currentEndpoint = defaultLocation.pathname.substring(1);
  const navigate = useNavigate();
  const [expandSidebar, setExpandSidebar] = useState(false);
  const openNav = () => {
    setExpandSidebar(!expandSidebar);

    expandSidebar
      ? (document.getElementById("sidenav").className = "SidebarWrapper toggle")
      : (document.getElementById("sidenav").className = "SidebarWrapper");
    expandSidebar
      ? (document.getElementById("ContentWrap").className =
          "ContentWrap contentToggle")
      : (document.getElementById("ContentWrap").className = "ContentWrap");

    const timeout = setTimeout(() => {
      dispatch(makeCustom({ value: !rerender.value }));
    }, 300);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    openNav();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const upgradePlan = () => {
    navigate("/pricing");
  };

  return (
    <>
      <div className="SidebarWrapper" id="sidenav">
        <Button
          className="navbar-toggle collapse in"
          data-toggle="collapse"
          id="menu-toggle-2"
          onClick={() => openNav()}
        >
          <img src={MenuIcon} />
        </Button>

        <ul className="sidebar-nav nav-pills nav-stacked" id="menu">
          <li className={currentEndpoint == "dashboard" ? "active" : ""}>
            <Link to={"/dashboard"}>
              <span className="icon-inner pull-left dashboardIcon"></span>{" "}
              <span className="toggleHide">Dashboard</span>
            </Link>
          </li>
          {/* Hide ...........................................................................start */}
          {/* <li>
            <a href="#">
              <span className="icon-inner pull-left notifyIcon"></span>
              <span className="toggleHide">Notification</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <span className="icon-inner pull-left taskIcon"></span>
              <span className="toggleHide">Tasks</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              {" "}
              <span className="icon-inner pull-left filesIcon"></span>
              <span className="toggleHide">FILES FOLDERS</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <span className="icon-inner pull-left calendericon"></span>
              <span className="toggleHide">Calendar</span>
            </a>
          </li> */}
          {/* Hide ...........................................................................end */}
          <li className={currentEndpoint == "teams" ? "active" : ""}>
            <Link to={"/teams"}>
              <span className="icon-inner pull-left teamsIcon"></span>
              <span className="toggleHide">TEAMS</span>
            </Link>
          </li>
          <li className={currentEndpoint == "wall" ? "active" : ""}>
            <Link to={"/wall"}>
              <span className="icon-inner pull-left wallIcon"></span>
              <span className="toggleHide">WALL</span>
            </Link>
          </li>
          <li className={currentEndpoint == "designmate" ? "active" : ""}>
            <Link to={"/designmate"}>
              <span className="icon-inner pull-left projectIcon"></span>
              <span className="toggleHide">designmate</span>
            </Link>
          </li>
          <li className={currentEndpoint == "wall/reminders" ? "active" : ""}>
            <Link to={"/wall/reminders"}>
              <span className="icon-inner pull-left reminderIcon"></span>
              <span className="toggleHide">REMINDER</span>
            </Link>
          </li>

          {/* Hide ...........................................................................start */}
          {/* <li>
            <a href="#">
              <span className="icon-inner pull-left cahtIcon"></span>
              <span className="toggleHide">CHAT</span>
            </a>
          </li> */}
          {/* <li>
            <a href="#">
              <span className="icon-inner pull-left mailIcon"></span>
              <span className="toggleHide">MAIL</span>
            </a>
          </li> */}
          {/* Hide ...........................................................................end */}
          {/* <li
            className={
              [
                "categories",
                "tags",
                "seasons",
                "fabrics",
                "colorLibrary",
              ].includes(currentEndpoint)
                ? "active"
                : ""
            }
          >
            <a href="javascript:;">
              <span className="icon-inner pull-left mailIcon"></span>
              <Dropdown id="manageMenu" className="toggleHide">
                <Dropdown.Toggle>Manage</Dropdown.Toggle>
                <Dropdown.Menu align="right">
                  <Dropdown.Item as={Link} to={"/categories"}>
                    Manage Categories
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/tags"}>
                    Manage Tags
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/seasons"}>
                    Manage Seasons
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/fabrics"}>
                    Manage Fabrics
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/colorLibrary"}>
                    Manage Color Library
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </a>
             
          </li> */}

          <li
            className={
              [
                "categories",
                "tags",
                "seasons",
                "fabrics",
                "colorLibrary",
              ].includes(currentEndpoint)
                ? "active manage"
                : "manage"
            }
          >
            <a href="javascript:;">
              <Dropdown id="manageMenu" className="toggleHide">
                <Dropdown.Toggle>
                  {" "}
                  <span className="icon-inner pull-left mailIcon"></span> Manage
                </Dropdown.Toggle>
                <Dropdown.Menu align="right">
                  <Dropdown.Item as={Link} to={"/categories"}>
                    Manage Categories
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/tags"}>
                    Manage Tags
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/seasons"}>
                    Manage Seasons
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/fabrics"}>
                    Manage Material
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={"/colorLibrary"}>
                    Color Library
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </a>
          </li>

          <div className="planBtnWrap text-center">
            <Button className="plansBtn" onClick={() => upgradePlan()}>
              <img src={priceIcon} alt="" /> <span>Upgrade Plan</span>
            </Button>
          </div>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
